import { theme } from '@styles/theme';
import clsx from 'clsx';
import Image from 'next/image';
import ContainImage from '../ContainImage';
import ReactPlayer from 'react-player';
import PlayIcon from '../../../assets/images/icons/play.svg';

interface ImageBoxProps {
  image: string;
  background_color: string;
  variant: 'VERTICAL' | 'HORIZONTAL' | 'CENTER';
  description?: string;
  movie: string;
  movie_thumbnail: string;
}

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=691%3A11666
 */
const ImageBox = (props: ImageBoxProps) => {
  const {
    image,
    variant,
    background_color,
    description,
    movie,
    movie_thumbnail,
  } = props;

  const backgroundColor = background_color
    ? background_color
    : theme.colors.neutral[1];

  switch (variant) {
    case 'VERTICAL':
      return (
        <div className={clsx(`relative aspect-1`)} style={{ backgroundColor }}>
          <div className="mx-[13.3%] relative h-full">
            <Image
              src={image}
              layout="fill"
              objectPosition="center"
              objectFit="cover"
              alt=""
            />
          </div>
        </div>
      );
    case 'CENTER':
      return (
        <div
          className={clsx(`relative aspect-1 flex mb-[6.45%] mr-[6.45%]`)}
          style={{ backgroundColor }}
        >
          <div
            className={`relative flex-1 w-full h-full mt-[6.45%] ml-[6.45%] -mb-[6.45%] -mr-[6.45%]`}
            style={{ backgroundColor }}
          >
            <Image
              src={image}
              layout="fill"
              objectPosition="center"
              objectFit="cover"
              alt=""
            />
          </div>
        </div>
      );
    default:
      return (
        <div className={clsx('relative max-w-full')}>
          <div
            className={`mx-auto lg:mx-[7.5%] h-full flex items-center aspect-1 max-w-[44.8rem]`}
            style={{ backgroundColor }}
          >
            <div className="flex-1 -mx-[3%] sm:-mx-[7.5%]">
              {image ? (
                <ContainImage src={image} />
              ) : (
                <ReactPlayer
                  url={movie}
                  controls={true}
                  light={movie_thumbnail}
                  playIcon={<PlayIcon />}
                  playing={true}
                  width="100%"
                  height="285px"
                />
              )}
            </div>
          </div>
          {!!description && (
            <div
              className="text-center mt-12 text-neutral-4 text-xs"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      );
  }
};

export default ImageBox;
